import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";
import Axios from "axios";
import { connect } from "react-redux";

import styles from "./index.module.scss";

import BesteronLogoImg from "../../../assets/presentation/images/besteron-widget-logo.svg";
import PciDssLogoImg from "../../../assets/presentation/images/pci-dss-widget-logo.svg";
import Guidepost from "./Guidepost";
import Footer from "./Footer";
import { ComponentTypeEnum } from "../../../utils/ComponentTypeEnum";
import PaymentTypeSwitch from "./PaymentTypeSwitch";
import Loading from "./Loading";
import CreditCard from "./CreditCard";
import BankButtons from "./BankButtons";
import ReturnToWebsite from "../../../components/presentation/ReturnToWebsite";
import * as languageAction from "../../../store/actions/languageAction";
import { getCodeOrDefaultByCode } from "../../../utils/LanguageEnum";

function Widget(props) {
  const [initialLoad, setInitialLoad] = useState(false);
  const [initLoading, setInitLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [widgetData, setWidgetData] = useState(false);
  const [userSavedCards, setUserSavedCards] = useState([]);
  const [visibleComponent, setVisibleComponent] = useState("guidepost");
  const [customerLogo, setCustomerLogo] = useState(null);
  const [bankType, setBankType] = useState(false);
  const [bankCodes, setBankCodes] = useState([]);
  const [canPostCardForm, setCanPostCardForm] = useState(false);
  const [postCardFormData, setPostCardFormData] = useState(null);
  const [forceCardRedirect, setForceCardRedirect] = useState(false);

  const history = useHistory();
  const cardForm = useRef();

  useEffect(() => {
    let parsedWidgetData = getWidgetDataAndSanitize();

    if (!initialLoad) {
      setInitialLoad(true);
      paymentIntentionRequest(parsedWidgetData);
    }
  });

  const getWidgetDataAndSanitize = () => {
    let parsedWidgetData = QueryString.parse(history.location.search);

    if (parsedWidgetData["hideReturn"] === undefined)
      parsedWidgetData.hideReturn = false;

    let hideReturn = parsedWidgetData.hideReturn.toString().toLowerCase();

    if (hideReturn === true || hideReturn === "true")
      parsedWidgetData.hideReturn = true;
    else parsedWidgetData.hideReturn = false;

    if (
      parsedWidgetData["email"] === undefined ||
      parsedWidgetData["email"] === "undefined" ||
      parsedWidgetData["email"] === "null"
    )
      parsedWidgetData.email = null;

    if (
      parsedWidgetData["desc"] === undefined ||
      parsedWidgetData["desc"] === "undefined" ||
      parsedWidgetData["desc"] === "null"
    )
      parsedWidgetData.desc = null;

    return parsedWidgetData;
  };

  const paymentIntentionRequest = (parsedWidgetData) => {
    setInitLoading(true);

    Axios.post("/public/payment-intention", parsedWidgetData)
      .then((response) => {
        if (response.data.isValid === true) {
          setWidgetData(parsedWidgetData);
          setUserSavedCards(response.data.userSavedCards);
          setCustomerLogo(response.data.base64Logo);
          setBankCodes(response.data.bankCodes);
          setForceCardRedirect(response.data.forceCardRedirect);
          setInitLoading(false);

          let languageCode = getCodeOrDefaultByCode(parsedWidgetData.language);

          props.changeLanguage(languageCode);
        } else {
          history.push("/public/invalid-payment-intention");
        }
      })
      .catch((response) => {
        history.push("/public/invalid-payment-intention");
      });
  };

  const showCustomerLogo = (customerLogo) => {
    if (customerLogo !== null && customerLogo !== undefined)
      return (
        <div className={styles["customer-logo"]}>
          <img
            src={"data:image/png;base64, " + customerLogo}
            alt="Customer logo"
          />
        </div>
      );
  };

  const getVisibleComponent = (component) => {
    if (
      getPropertyCaseInsensitivelyValue(widgetData, "paymentMethod") === "CARD"
    )
      return (
        <div className={styles["only-card"]}>
          <CreditCard
            data={widgetData}
            creditCardRequest={creditCardRequest}
            userSavedCards={userSavedCards}
            savedCardRequest={savedCardRequest}
            forceCardRedirect={forceCardRedirect}
            deleteSavedCard={deleteSavedCard}
          />
        </div>
      );

    if (
      getPropertyCaseInsensitivelyValue(widgetData, "paymentMethod") ===
      "BUTTON"
    )
      return (
        <div className={styles["only-button"]}>
          <BankButtons
            data={widgetData}
            setBankType={setBankType}
            bankPaymentRequest={bankPaymentRequest}
            bankCodes={bankCodes}
          />
        </div>
      );

    if (component === ComponentTypeEnum.BankButtons)
      return (
        <PaymentTypeSwitch
          type={ComponentTypeEnum.BankButtons}
          data={widgetData}
          setBankType={setBankType}
          bankPaymentRequest={bankPaymentRequest}
          bankCodes={bankCodes}
          creditCardRequest={creditCardRequest}
          userSavedCards={userSavedCards}
          savedCardRequest={savedCardRequest}
          deleteSavedCard={deleteSavedCard}
          forceCardRedirect={forceCardRedirect}
        />
      );
    else if (component === ComponentTypeEnum.CreditCard)
      return (
        <PaymentTypeSwitch
          type={ComponentTypeEnum.CreditCard}
          data={widgetData}
          setBankType={setBankType}
          bankPaymentRequest={bankPaymentRequest}
          bankCodes={bankCodes}
          creditCardRequest={creditCardRequest}
          userSavedCards={userSavedCards}
          savedCardRequest={savedCardRequest}
          deleteSavedCard={deleteSavedCard}
          forceCardRedirect={forceCardRedirect}
        />
      );
    else
      return (
        <Guidepost
          onComponentChange={setVisibleComponent}
          hideReturn={widgetData.hideReturn}
        />
      );
  };

  const bankPaymentRequest = () => {
    setLoading(true);

    widgetData.bank = bankType;

    Axios.post("/public/bank-payment", widgetData)
      .then((response) => {
        if (response.data.isValid === true) {
          window.top.location = response.data.redirectUrl;
        } else {
          history.push(
            `/public/payment-failed?hideReturn=${widgetData.hideReturn}`
          );
        }
      })
      .catch((response) => {
        history.push(
          `/public/payment-failed?hideReturn=${widgetData.hideReturn}`
        );
      });
  };

  const realCardPayment = (data) => {
    if (forceCardRedirect) {
      window.top.location = data.redirectUrl;
    } else {
      if (data.responseType === "Redirect") {
        window.top.location = data.redirectUrl;
      } else {
        setPostCardFormData(data);
        setCanPostCardForm(true);

        setTimeout(() => {
          cardForm.current.submit();
        }, 2000);
      }
    }
  };

  const virtualCardPayment = (data) => {
    window.top.location = data.redirectUrl;
  };

  const processCardPayment = (response) => {
    if (
      widgetData.virtual === "true" ||
      widgetData.virtual === "True" ||
      widgetData.virtual === true
    ) {
      virtualCardPayment(response.data);
    } else {
      realCardPayment(response.data);
    }
  };

  const creditCardRequest = (cardData) => {
    setLoading(true);

    widgetData.cardNumber = cardData.creditCard;
    widgetData.expMonth = cardData.month;
    widgetData.expYear = cardData.year;
    widgetData.cvv = cardData.cvv;
    widgetData.saveCard = cardData.saveCard;

    Axios.post("/public/card-payment", widgetData)
      .then((response) => {
        if (response.data.isValid === true) {
          processCardPayment(response);
        } else {
          history.push(
            `/public/payment-failed?hideReturn=${widgetData.hideReturn}`
          );
        }
      })
      .catch((response) => {
        history.push(
          `/public/payment-failed?hideReturn=${widgetData.hideReturn}`
        );
      });
  };

  const savedCardRequest = (savedCardId) => {
    setLoading(true);

    widgetData.savedCardId = savedCardId;

    Axios.post("/public/saved-card-payment", widgetData)
      .then((response) => {
        if (response.data.isValid === true) {
          processCardPayment(response);
        } else {
          history.push(
            `/public/payment-failed?hideReturn=${widgetData.hideReturn}`
          );
        }
      })
      .catch((response) => {
        history.push(
          `/public/payment-failed?hideReturn=${widgetData.hideReturn}`
        );
      });
  };

  const deleteSavedCard = (savedCardId) => {
    var filteredUserSavedCards = userSavedCards.filter(
      (value) => value.id !== savedCardId
    );

    setUserSavedCards(filteredUserSavedCards);

    Axios.post("/public/delete-saved-card", { id: savedCardId });
  };

  const getPropertyCaseInsensitivelyValue = (object, propertyName) => {
    propertyName = propertyName.toLowerCase();

    for (let property in object) {
      if (property.toLowerCase() === propertyName) return object[property];
    }
  };

  if (initLoading)
    return (
      <Loading subTitleTranslationId="global.presentation.loading.initPayment" />
    );
  else if (loading && canPostCardForm)
    return (
      <>
        <form
          ref={cardForm}
          style={{ visibility: "hidden" }}
          method="POST"
          target="_self"
          action={
            postCardFormData.responseType === "V1"
              ? postCardFormData.secure3DResponseV1.acsUrl
              : postCardFormData.secure3DResponseV2_Challenge.acsUrl
          }
        >
          {postCardFormData.responseType === "V1" ? (
            <>
              <input
                type="hidden"
                name="MD"
                value={postCardFormData.secure3DResponseV1.md}
              />
              <input
                type="hidden"
                name="PaReq"
                value={postCardFormData.secure3DResponseV1.paReq}
              />
              <input
                type="hidden"
                name="TermUrl"
                value={postCardFormData.secure3DResponseV1.termUrl}
              />
            </>
          ) : (
            <>
              <input
                type="hidden"
                name="creq"
                value={postCardFormData.secure3DResponseV2_Challenge.cReq}
              />
              <input
                type="hidden"
                name="threedssessiondata"
                value={
                  postCardFormData.secure3DResponseV2_Challenge.threeDSSessionData
                }
              />
              <input
                type="hidden"
                name="termurl"
                value={postCardFormData.secure3DResponseV2_Challenge.termUrl}
              />
            </>
          )}
        </form>
        <Loading subTitleTranslationId="global.presentation.loading.makePayment" />
      </>
    );
  else if (loading)
    return (
      <Loading subTitleTranslationId="global.presentation.loading.makePayment" />
    );

  return (
    <section className={styles.main}>
      <div className={styles.content}>
        {showCustomerLogo(customerLogo)}
        {getVisibleComponent(visibleComponent)}
        <ReturnToWebsite
          className={styles.return}
          hideReturn={widgetData.hideReturn}
        />
        <Footer />
        <div className={styles["footer-logo-container"]}>
          <div className={styles["besteron-logo"]}>
            <img src={BesteronLogoImg} alt="Besteron logo" />
          </div>
          <div className={styles["pci-dss-logo"]}>
            <img src={PciDssLogoImg} alt="PCI-DSS logo" />
          </div>
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => {
  return {
    language: state.language.language,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    changeLanguage: (language) =>
      dispatch(languageAction.changeLanguage(language)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Widget);
