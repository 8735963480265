import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./ReturnToWebsite.module.scss";

import BackArrowIcon from "../../components/presentation/Icons/BackArrowIcon";

function ReturnToWebsite(props) {

    const dispatchCloseEvent = () => {
        window.parent.postMessage('besteron.close', '*');
    }

    if(props.hideReturn === true || props.hideReturn === "true" || props.hideReturn === "True")
        return "";

    return (
        <div 
            className={[
                styles.content,
                props.className
            ].join(" ")}
            onClick={dispatchCloseEvent}
        >
            <BackArrowIcon />
            <span>
                <FormattedMessage id="global.presentation.returnToWebsite">
                    {(...props) => props}
                </FormattedMessage>
            </span>
        </div>
    );
}

export default ReturnToWebsite;