import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";

import Respond from "../../../../components/presentation/Respond";

function PaymentFailed(props) {
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [queryStringData, setQueryStringData] = useState({})

    const history = useHistory();

    useEffect(() => {
        var parsedQueryStringData = QueryString.parse(history.location.search);

        if(isInitialLoad) 
        {
            setIsInitialLoad(false);
            setQueryStringData(parsedQueryStringData);
        }
    });

    return (
        <Respond 
            type="error"
            titleTranslationId="global.presentation.paymentFailed.title"
            hideReturn={queryStringData.hideReturn || props.hideReturn}
        />
    );
}

export default PaymentFailed;