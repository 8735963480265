import React from "react";

import Respond from "../../../../components/presentation/Respond";

function Loading(props) {
    return (
        <Respond 
            type="loading"
            titleTranslationId="global.presentation.waitPlease"
            subTitleTranslationId={props.subTitleTranslationId}
            hideReturn={true}
        />
    );
}

export default Loading;