import React from "react";

import styles from "./index.module.scss";

import Text from "../../../../components/presentation/Text";
import BankIcon from "../../../../components/presentation/Icons/BankIcon";
import CardIcon from "../../../../components/presentation/Icons/CardIcon";
import { ComponentTypeEnum } from "../../../../utils/ComponentTypeEnum";
import Popover from "../../../../components/presentation/Popover";

function Guidepost(props) {
  return (
    <section className={styles.content}>
      <Text translationId="global.presentation.guidepost.title" />
      <div className={styles.options}>
        <div
          className={styles.option}
          onClick={() => props.onComponentChange(ComponentTypeEnum.CreditCard)}
        >
          <CardIcon />
          <Text translationId="global.presentation.guidepost.card" />
        </div>
        <Popover
          textTranslationId="global.presentation.instantBankTransfer"
          placement="bottom"
        >
          <div
            className={styles.option}
            onClick={() =>
              props.onComponentChange(ComponentTypeEnum.BankButtons)
            }
          >
            <BankIcon />
            <Text translationId="global.presentation.guidepost.bankButtons" />
          </div>
        </Popover>
      </div>
    </section>
  );
}

export default Guidepost;
