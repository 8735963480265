import React from "react";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "./Loading.module.scss";

import BesteronLogo from "../../assets/presentation/images/besteron-b-red.svg";

function Loading(props) {
    return (
        <div className={styles.container}>
            <div className={styles.image} style={{ backgroundImage: `url(${BesteronLogo})` }} />
            <LoadingOutlined />
        </div>
    );
}

export default Loading;