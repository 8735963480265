import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { withRouter } from "react-router-dom";

import "antd/dist/antd.css";

import Routers from "./components/Routers";
import * as languageAction from "./store/actions/languageAction";

import global from "./translations/global.json";
import presenationGlobal from "./translations/presentation/global.json";
import Loading from "./components/presentation/Loading";

const LayoutPresentation = React.lazy(() => import("./components/presentation/Layout"));

class App extends Component {
  static displayName = App.name;

  render() {
    if(this.props.location.pathname.includes("www"))
      this.props.history.push(this.props.location.pathname.replace("www.", ""))

    let combinedTranslation = {
      "en": {
        ...global["en"],
        ...presenationGlobal["en"]
      },
      "sk": {
        ...global["sk"],
        ...presenationGlobal["sk"],
      },
      "cz": {
        ...global["cz"],
        ...presenationGlobal["cz"],
      },
      "hu": {
        ...global["hu"],
        ...presenationGlobal["hu"],
      },
      "pl": {
        ...global["pl"],
        ...presenationGlobal["pl"],
      },
      "ro": {
        ...global["ro"],
        ...presenationGlobal["ro"],
      },
      "de": {
        ...global["de"],
        ...presenationGlobal["de"],
      }
    }

    return (
      <IntlProvider
        key={this.props.language}
        locale={this.props.language}
        messages={combinedTranslation[this.props.language]}
      >
        <Suspense fallback={<Loading />}
        >
          <LayoutPresentation>
            <Suspense fallback={<Loading />}>
              <Routers onChangeLanguage={this.onChangeLanguage} />
            </Suspense>
          </LayoutPresentation>
        </Suspense>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => {
  return {
    language: state.language.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    change: lang => dispatch(languageAction.request(lang))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(App));
