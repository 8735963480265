import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./Title.module.scss";

function Title(props) {
    return (
        <span className={[
                props.className,
                styles.title,
            ].join(" ")}
        >
            <FormattedMessage id={props.translationId}>
                {(...props) => props}
            </FormattedMessage>
        </span>
    );
}

export default Title;