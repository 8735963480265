import React, { Component } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

class ProtectedRoute extends Component {
    static displayName = ProtectedRoute.name;

    render() {
        const { component: Component, ...rest } = this.props;
        let intersection = this.props.requiredRoles.filter(rr => this.props.roles.includes(rr));

        return (
            <Route {...rest} render={
                props => {
                    if (this.props.isAuthenticated && intersection.length > 0) {
                        return <Component {...props} />;
                    }
                    else {
                        return <Redirect to={this.props.redirectTo} />;
                    }
                }
            } />
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.authentication.isAuthenticated,
        roles: state.authentication.roles
    };
};

const mapDispatchToProps = dispatch => {
    return {};
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProtectedRoute);