import React from "react";

import GibaczpxImg from "../assets/presentation/images/banks/gibaczpx.svg";
import KombczppImg from "../assets/presentation/images/banks/kombczpp.svg";
import CekoczppImg from "../assets/presentation/images/banks/cekoczpp.svg";
import EraxczppImg from "../assets/presentation/images/banks/eraxczpp.svg";
import BrexczppImg from "../assets/presentation/images/banks/brexczpp.svg";
import RzbcczppImg from "../assets/presentation/images/banks/rzbcczpp.svg";
import AgbaczppImg from "../assets/presentation/images/banks/agbaczpp.svg";
import FiobczppImg from "../assets/presentation/images/banks/fiobczpp.svg";
import BacxczppImg from "../assets/presentation/images/banks/bacxczpp.svg";

export const CzechBanksEnum = Object.freeze([
    { code: "GIBACZPX", logo: <img src={GibaczpxImg} alt="" /> },
    { code: "KOMBCZPP", logo: <img src={KombczppImg} alt="" /> },
    { code: "CEKOCZPP", logo: <img src={CekoczppImg} alt="" /> },
    { code: "ERAXCZPP", logo: <img src={EraxczppImg} alt="" /> },
    { code: "BREXCZPP", logo: <img src={BrexczppImg} alt="" /> },
    { code: "RZBCCZPP", logo: <img src={RzbcczppImg} alt="" /> },
    { code: "AGBACZPP", logo: <img src={AgbaczppImg} alt="" /> },
    { code: "FIOBCZPP", logo: <img src={FiobczppImg} alt="" /> },
    { code: "BACXCZPP", logo: <img src={BacxczppImg} alt="" /> }
]);

export const getObjectByCode = code => {
    return CzechBanksEnum.find(czechBank => {
        return czechBank.code === code;
    });
}

export const getObjectsByCodes = codes => {
    return CzechBanksEnum.filter(czechBank => {
        return codes.includes(czechBank.code);
    });
}