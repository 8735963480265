import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="61"
    height="61"
    viewBox="0 0 61 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      y="35.3555"
      width="10"
      height="35"
      rx="5"
      transform="rotate(-45 0 35.3555)"
      fill="#3ECE8E"
    />
    <rect
      x="53.033"
      y="17.6777"
      width="10"
      height="50"
      rx="5"
      transform="rotate(45 53.033 17.6777)"
      fill="#3ECE8E"
    />
  </svg>
);

const SuccessIcon = (props) => {
  return <Icon component={Svg} {...props} />;
};

export default SuccessIcon;
