import React, { useState, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { Descriptions } from "antd";
import Axios from "axios";
import QueryString from "query-string";

import styles from "./index.module.scss";

import BesteronLogoImg from "../../../assets/presentation/images/besteron-widget-logo.svg";
import Footer from "../Widget/Footer";

function VirtualPayment(props) {
  const [initialLoad, setInitialLoad] = useState(false);
  const [parsedWidgetData, setParsedWidgetData] = useState({});

  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    var parsedWidgetData = QueryString.parse(history.location.search);

    if (!initialLoad) {
      setInitialLoad(true);
      setParsedWidgetData(parsedWidgetData);
    }
  });

  const sendVirtualRequest = (status) => {
    parsedWidgetData.status = status;

    Axios.post("/public/process-virtual-payment", parsedWidgetData)
      .then((response) => {
        window.top.location = response.data.redirectUrl;
      })
      .catch((response) => {
        history.push(
          `/public/payment-failed?hideReturn=${parsedWidgetData.hideReturn}`
        );
      });
  };

  return (
    <section className={styles.main}>
      <div className={styles.content}>
        <Descriptions
          title={intl.formatMessage({
            id: "global.presentation.virtualPayment.title",
          })}
          bordered
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            label={intl.formatMessage({
              id: "global.presentation.virtualPayment.shop",
            })}
          >
            <FormattedMessage id="global.presentation.virtualPayment.shop.value">
              {(...props) => props}
            </FormattedMessage>
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({
              id: "global.presentation.virtualPayment.web",
            })}
          >
            <a
              href={intl.formatMessage({
                id: "global.presentation.virtualPayment.web.value",
              })}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="global.presentation.virtualPayment.web.value">
                {(...props) => props}
              </FormattedMessage>
            </a>
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({
              id: "global.presentation.virtualPayment.type",
            })}
          >
            {parsedWidgetData.type}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({
              id: "global.presentation.virtualPayment.variableSymbol",
            })}
          >
            {parsedWidgetData.vs}
          </Descriptions.Item>
          <Descriptions.Item
            label={intl.formatMessage({
              id: "global.presentation.virtualPayment.price",
            })}
          >
            {parsedWidgetData.amnt}
          </Descriptions.Item>
        </Descriptions>
        <div className={styles.buttons}>
          <button onClick={() => sendVirtualRequest("OK")}>
            <FormattedMessage id="global.presentation.virtualPayment.successfulPayment">
              {(...props) => props}
            </FormattedMessage>
          </button>
          <button
            className={styles.danger}
            onClick={() => sendVirtualRequest("FAIL")}
          >
            <FormattedMessage id="global.presentation.virtualPayment.failedPayment">
              {(...props) => props}
            </FormattedMessage>
          </button>
        </div>
        <Footer />
        <div className={styles["besteron-logo"]}>
          <img src={BesteronLogoImg} alt="Besteron logo" />
        </div>
      </div>
    </section>
  );
}

export default VirtualPayment;
