import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./index.module.scss";

import BesteronLogo from "../../../assets/presentation/images/404-heart.png";

function NotFound(props) {
    return (
        <div className={styles.container}>
            <div className={styles["error-block"]}>
                <div className={styles.image} style={{ backgroundImage: `url(${BesteronLogo})` }} />
                <h1>
                    404
                </h1>
            </div>
            <h2>
                <FormattedMessage id="global.presentation.pageNotFound">
                    {(...props) => props}
                </FormattedMessage>
            </h2>
        </div>
    );
}

export default NotFound;