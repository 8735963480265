import React, { useState } from "react";

import styles from "./index.module.scss";

import { ComponentTypeEnum } from "../../../../utils/ComponentTypeEnum";
import BankIcon from "../../../../components/presentation/Icons/BankIcon";
import CardIcon from "../../../../components/presentation/Icons/CardIcon";
import Text from "../../../../components/presentation/Text";
import BankButtons from "../BankButtons";
import CreditCard from "../CreditCard";

function PaymentTypeSwitch(props) {
    const [activeKey, setActiveKey] = useState(props.type);

    const getPaymentComponent = activeKey => {
        if(activeKey === ComponentTypeEnum.BankButtons)
            return <BankButtons 
                data={props.data} 
                setBankType={props.setBankType}
                bankPaymentRequest={props.bankPaymentRequest}
                bankCodes={props.bankCodes}
            />
        else if(activeKey === ComponentTypeEnum.CreditCard)
            return <CreditCard 
                data={props.data} 
                creditCardRequest={props.creditCardRequest}
                userSavedCards={props.userSavedCards}
                savedCardRequest={props.savedCardRequest}
                deleteSavedCard={props.deleteSavedCard}
                forceCardRedirect={props.forceCardRedirect}
            />
    }

    return (
        <section className={styles.content}>
            <div className={styles.tabs}>
                <div 
                    className={[
                        styles.tab,
                        activeKey === ComponentTypeEnum.CreditCard ? styles.active : ""
                    ].join(" ")}
                    onClick={() => setActiveKey(ComponentTypeEnum.CreditCard)}
                >
                    <div className={styles["background-animation"]} />
                    <CardIcon />
                    <Text translationId="global.presentation.paymentTypeSwitch.card" />
                </div>
                <div 
                    className={[
                        styles.tab,
                        activeKey === ComponentTypeEnum.BankButtons ? styles.active : ""
                    ].join(" ")}
                    onClick={() => setActiveKey(ComponentTypeEnum.BankButtons)}
                >
                    <div className={styles["background-animation"]} />
                    <BankIcon />
                    <Text translationId="global.presentation.paymentTypeSwitch.bankButtons" />
                </div>
            </div>
            <div className={styles["payment-type"]}>
                {getPaymentComponent(activeKey)}
            </div>
        </section>
    );
}

export default PaymentTypeSwitch;