import React from "react";

import VubPayImg from "../assets/presentation/images/banks/vubpay.svg";
import SporoPayImg from "../assets/presentation/images/banks/sporopay.svg";
import TatraPayImg from "../assets/presentation/images/banks/tatrapay.svg";
import UniPayImg from "../assets/presentation/images/banks/unipay.svg";
import PostaPayImg from "../assets/presentation/images/banks/postapay.svg";
import ViamoImg from "../assets/presentation/images/banks/viamo.svg";

export const SlovakiaBanksEnum = Object.freeze([
    { code: "VUBPAY", logo: <img src={VubPayImg} alt="" /> },
    { code: "SPOROPAY", logo: <img src={SporoPayImg} alt="" /> },
    { code: "TATRAPAY", logo: <img src={TatraPayImg} alt="" /> },
    { code: "UNIPAY", logo: <img src={UniPayImg} alt="" /> },
    { code: "POSTAPAY", logo: <img src={PostaPayImg} alt="" /> },
    { code: "VIAMO", logo: <img src={ViamoImg} alt="" /> }
]);

export const getObjectByCode = code => {
    return SlovakiaBanksEnum.find(slovakiaBank => {
        return slovakiaBank.code === code;
    });
}

export const getObjectsByCodes = codes => {
    return SlovakiaBanksEnum.filter(slovakiaBank => {
        return codes.includes(slovakiaBank.code);
    });
}