import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./Text.module.scss";

function Text(props) {
    const showOnlyText = onlyText => {
        if(onlyText === true)
            return props.text;
        else 
            return (
                <FormattedMessage id={props.translationId}>
                    {(...props) => props}
                </FormattedMessage>
            );
    }

    return (
        <span className={[
                props.className,
                styles.text,
            ].join(" ")}
        >
            {showOnlyText(props.onlyText)}
        </span>
    );
}

export default Text;