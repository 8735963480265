export const CurrencyEnum = Object.freeze([
    { code: "978", name: "Eur", symbol: "€"},
    { code: "203", name: "Czk", symbol: "Kč"},
    { code: "840", name: "Usd", symbol: "$"},
    { code: "826", name: "Gbp", symbol: "£"},
    { code: "348", name: "Huf", symbol: "Ft"},
    { code: "985", name: "Pln", symbol: "zł"},
    { code: "756", name: "Chf", symbol: "Fr."},
    { code: "208", name: "Dkk", symbol: "Kr."},
    { code: "980", name: "Uah", symbol: "₴"},
    { code: "946", name: "Ron", symbol: "lei"},
    { code: "807", name: "Mkd", symbol: "ден"},
    { code: "975", name: "Bgn", symbol: "лв"},
    { code: "191", name: "Hrk", symbol: "kn"},
    { code: "977", name: "Bam", symbol: "km"},
    { code: "352", name: "Isk", symbol: "kr"},
    { code: "941", name: "Rsd", symbol: "дин."}
]);

export const getObjectByCode = code => {
    return CurrencyEnum.find(currency => {
        return currency.code === code;
    });
}