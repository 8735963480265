import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="39"
    height="39"
    viewBox="0 0 39 39"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.2132 19.1421L36.8198 29.7487C38.7724 31.7014 38.7724 34.8672 36.8198 36.8198C34.8672 38.7724 31.7014 38.7724 29.7487 36.8198L19.1421 26.2132L8.53553 36.8198C6.58291 38.7724 3.41709 38.7724 1.46447 36.8198C-0.488155 34.8672 -0.488155 31.7014 1.46447 29.7487L12.0711 19.1421L1.46447 8.53553C-0.488155 6.58291 -0.488155 3.41709 1.46447 1.46447C3.41709 -0.488155 6.58291 -0.488155 8.53553 1.46447L19.1421 12.0711L29.7487 1.46447C31.7014 -0.488155 34.8672 -0.488155 36.8198 1.46447C38.7724 3.41709 38.7724 6.58291 36.8198 8.53553L26.2132 19.1421Z"
      fill="#E21744"
    />
  </svg>
);

const ErrorIcon = (props) => {
  return <Icon component={Svg} {...props} />;
};

export default ErrorIcon;
