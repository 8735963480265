import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="4"
      width="27"
      height="25"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.2002 4.21362H29.495V28.8H3.2002V4.21362Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.4766 26.6562C27.4766 26.7075 27.4654 26.7523 27.4454 26.7779H10.2646C10.2438 26.7523 10.2238 26.7075 10.2238 26.6562V14.7595L10.2334 14.7411V14.7339V14.7259L10.2438 14.7179V14.7099V14.7075V14.7051V14.6971L10.255 14.6923V14.6891V14.6867V14.6811L10.2646 14.6763V14.6715V14.6691H27.4454C27.4654 14.6971 27.4766 14.7395 27.4766 14.7859V26.6562ZM8.20457 14.7859V20.0691L5.24937 14.9435C5.21817 14.9027 5.21817 14.8611 5.21817 14.8219L5.24937 14.7987L20.0886 6.23226C20.1294 6.24746 20.1606 6.27866 20.1822 6.31786L20.2022 6.34586L23.8342 12.6419H10.2438C9.11017 12.6419 8.20457 13.6307 8.20457 14.7859ZM28.9326 13.3131V13.3075C28.559 12.8971 28.0382 12.6419 27.4654 12.6419H26.1654L21.9822 5.39385C21.951 5.32665 21.9094 5.25945 21.8574 5.19945C21.5766 4.76265 21.1598 4.45785 20.7022 4.31145C20.2134 4.15305 19.6718 4.18185 19.1934 4.42665C19.131 4.45225 19.079 4.48905 19.0166 4.52505L4.25017 13.0531L4.18857 13.0811C3.68857 13.3779 3.36537 13.8571 3.25177 14.3955V14.3979C3.13737 14.9003 3.21017 15.4619 3.50137 15.9531L8.20457 24.1091V26.6563C8.20457 27.2283 8.42377 27.7483 8.77657 28.1363C9.14137 28.5443 9.66137 28.8003 10.2438 28.8003H27.4654C28.0382 28.8003 28.559 28.5443 28.9326 28.1363C29.287 27.7483 29.495 27.2283 29.495 26.6563V14.7859C29.495 14.2155 29.287 13.6955 28.9326 13.3131Z"
        fill="currentColor"
      />
    </g>
    <mask
      id="mask1"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="3"
      y="4"
      width="27"
      height="25"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.19995 28.8H29.4951V4.21362H3.19995V28.8Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1895 21.6225H14.8639V18.9481H12.1895V21.6225ZM11.5863 22.832H15.4783C15.8111 22.832 16.0823 22.5584 16.0823 22.2256V18.3416C16.0823 18.0064 15.8111 17.7328 15.4783 17.7328H11.5863C11.2527 17.7328 10.9727 18.0064 10.9727 18.3416V22.2256C10.9727 22.5584 11.2527 22.832 11.5863 22.832Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.425 23.9734H11.5554C11.2226 23.9734 10.9514 24.247 10.9514 24.583V25.7094C10.9514 26.047 11.2226 26.3182 11.5554 26.3182H26.425C26.7586 26.3182 27.0394 26.047 27.0394 25.7094V24.583C27.0394 24.247 26.7586 23.9734 26.425 23.9734Z"
        fill="currentColor"
      />
    </g>
  </svg>
);

const CardIcon = (props) => {
  return <Icon component={Svg} {...props} />;
};

export default CardIcon;
