import React from "react";
import { InfoOutlined, LoadingOutlined } from "@ant-design/icons";

import styles from "./Respond.module.scss";

import BesteronLogoImg from "../../assets/presentation/images/besteron-widget-logo.svg";
import LogoImg from "../../assets/presentation/images/logo.svg";
import Title from "./Title";
import Text from "./Text";
import SuccessIcon from "./Icons/SuccessIcon";
import ErrorIcon from "./Icons/ErrorIcon";
import ReturnToWebsite from "./ReturnToWebsite";

function Respond(props) {
  const subTitleVisibility = (translationId) => {
    if (translationId === undefined || translationId === "") return "";
    else
      return (
        <Text className={styles["sub-title"]} translationId={translationId} />
      );
  };

  const getCircleByType = (type) => {
    if (type === "success")
      return (
        <div className={styles.circle}>
          <SuccessIcon />
        </div>
      );
    else if (type === "loading")
      return (
        <div className={styles.circle}>
          <LoadingOutlined />
          <img src={LogoImg} alt="loading" />
        </div>
      );
    else if (type === "error")
      return (
        <div className={styles.circle}>
          <ErrorIcon />
        </div>
      );
    else
      return (
        <div className={styles.circle}>
          <InfoOutlined />
        </div>
      );
  };

  const getStyleByType = (type) => {
    if (type === "success") return styles.success;
    else if (type === "loading") return styles.loading;
    else if (type === "error") return styles.error;
    else return styles.info;
  };

  return (
    <main className={getStyleByType(props.type)}>
      <div className={styles.content}>
        {getCircleByType(props.type)}
        <Title
          className={styles.title}
          translationId={props.titleTranslationId}
        />
        {subTitleVisibility(props.subTitleTranslationId)}
        <ReturnToWebsite hideReturn={props.hideReturn} />
        <div className={styles["besteron-logo"]}>
          <img src={BesteronLogoImg} alt="besteron" />
        </div>
      </div>
    </main>
  );
}

export default Respond;
