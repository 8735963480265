import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="30"
    height="19"
    viewBox="0 0 30 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.36"
      y="0.36"
      width="28.8"
      height="18"
      stroke="#9B9B9B"
      strokeWidth="0.72"
    />
    <rect x="0.719971" y="2.87988" width="28.08" height="4.32" fill="#9B9B9B" />
    <rect
      opacity="0.2"
      x="1.43994"
      y="9.36011"
      width="24.48"
      height="5.76"
      fill="#9B9B9B"
    />
    <circle
      cx="21.0599"
      cy="12.7799"
      r="5.76"
      stroke="#D0021B"
      strokeWidth="0.72"
    />
    <path
      d="M18.7834 14.3601V10.5182H18.3629L17.401 11.5089L17.689 11.8027L18.3053 11.1518V14.3601H18.7834ZM22.181 14.3601V13.9339H20.2975C21.3689 13.0871 22.1637 12.3729 22.1637 11.5895C22.1637 10.8235 21.5129 10.4606 20.8735 10.4606C20.3321 10.4606 19.8309 10.6795 19.5372 11.0654L19.8309 11.3707C20.0556 11.0827 20.4127 10.8868 20.8735 10.8868C21.2709 10.8868 21.6741 11.0999 21.6741 11.5895C21.6741 12.2519 20.9253 12.8913 19.5429 13.9799V14.3601H22.181ZM24.1503 14.4292C24.997 14.4292 25.5097 13.9627 25.5097 13.3233C25.5097 12.707 24.974 12.4075 24.6226 12.3787C24.9855 12.3153 25.4521 12.0388 25.4521 11.4859C25.4521 10.835 24.8876 10.4606 24.1503 10.4606C23.557 10.4606 23.1135 10.7198 22.837 11.0539L23.102 11.3534C23.3842 11.0481 23.6953 10.8868 24.1158 10.8868C24.5823 10.8868 24.974 11.1172 24.974 11.5435C24.974 11.987 24.565 12.1771 24.0812 12.1771C23.9257 12.1771 23.7471 12.1771 23.6838 12.1713V12.6091C23.7414 12.6033 23.9199 12.6033 24.0812 12.6033C24.6457 12.6033 25.0374 12.8049 25.0374 13.283C25.0374 13.7265 24.6975 14.003 24.1388 14.003C23.6838 14.003 23.2921 13.7956 23.0674 13.4961L22.7852 13.7956C23.0214 14.1412 23.4994 14.4292 24.1503 14.4292Z"
      fill="#4A4A4A"
    />
  </svg>
);

const CardBackIcon = (props) => {
  return <Icon component={Svg} {...props} />;
};

export default CardBackIcon;
