import { createReducer } from "redux-act";
import * as action from "../actions/languageAction";

const initialState = {
  language: "sk"
};

const languageReducer = createReducer(
  {
    [action.changeLanguage]: (state, payload) => {
      return {
        ...state,
        language: payload
      };
    }
  },
  initialState
);

export default languageReducer;
