import React from "react";
import { useIntl } from "react-intl";
import { Button } from "antd";

import styles from "./index.module.scss";

import BesteronLogoImg from "../../../assets/presentation/images/besteron-widget-logo.svg";

function Home(props) {
  const intl = useIntl();

  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <img src={BesteronLogoImg} alt="Besteron logo" />
        <span>
          {intl.formatMessage({ id: "global.presentation.home.redirectTitle" })}{" "}
          <a
            href={`https://${intl.formatMessage({
              id: "global.presentation.home.redirect",
            })}`}
          >
            {intl.formatMessage({
              id: "global.presentation.home.redirect",
            })}
          </a>{" "}
          {intl.formatMessage({ id: "global.presentation.home.redirectTitleAfter" })}
        </span>
        <a
          href={`https://${intl.formatMessage({
            id: "global.presentation.home.redirect",
          })}`}
        >
          <Button className={styles.button} type="primary">
            {intl.formatMessage({
              id: "global.presentation.home.redirectButton",
            })}
          </Button>
        </a>
      </div>
    </div>
  );
}

export default Home;
