import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.27747 11.2773C5.8858 11.6747 5.24464 11.6747 4.85297 11.2773L0.685497 7.04852C0.3053 6.66273 0.3053 6.04315 0.685497 5.65736C1.07363 5.26353 1.70898 5.26353 2.09711 5.65736L5.56522 9.17647L13.9029 0.716187C14.291 0.322351 14.9264 0.32235 15.3145 0.716187C15.6947 1.10198 15.6947 1.72155 15.3145 2.10734L6.27747 11.2773Z"
      fill="#1192BC"
    />
  </svg>
);

const CheckIcon = (props) => {
  return <Icon component={Svg} {...props} />;
};

export default CheckIcon;
