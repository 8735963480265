import React from "react";
import { FormattedMessage } from "react-intl";

import styles from "./index.module.scss";

import BesteronLogo from "../../../assets/presentation/images/logo.svg";

function ViamoNotification(props) {
  return (
    <div className={styles.container}>
      <div className={styles["logo-block"]}>
        <div
          className={styles.image}
          style={{ backgroundImage: `url(${BesteronLogo})` }}
        />
      </div>
      <h2>
        <FormattedMessage
          id="global.presentation.viamo.notification"
          values={{ br: <br /> }}
        >
          {(...props) => props}
        </FormattedMessage>
      </h2>
    </div>
  );
}

export default ViamoNotification;
