import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="30"
    height="18"
    viewBox="0 0 30 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 17.9786H29.3678V0H0V17.9786Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 17.9787H29.3678V15.3906H0V17.9787Z"
      fill="#F2B41B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2.58824H29.3678V0H0V2.58824Z"
      fill="#2A2C6B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4306 5.43997L12.8826 12.5541H11.0103L12.5585 5.43997H14.4306ZM22.3076 10.0337L23.2931 7.36201L23.86 10.0337H22.3076ZM24.3972 12.5541H26.1285L24.616 5.43997H23.0193C22.6591 5.43997 22.3559 5.64529 22.2214 5.96197L19.412 12.5541H21.3785L21.7687 11.4917H24.1705L24.3972 12.5541ZM19.5094 10.2315C19.5183 8.35439 16.8698 8.24975 16.8871 7.41126C16.8927 7.1561 17.14 6.88472 17.6809 6.81548C17.9489 6.78166 18.6888 6.75417 19.5275 7.13355L19.8557 5.62452C19.4048 5.4643 18.8255 5.30981 18.1041 5.30981C16.2535 5.30981 14.9514 6.27647 14.9407 7.66148C14.9286 8.6861 15.8708 9.25696 16.5788 9.59777C17.3095 9.94689 17.554 10.1706 17.5505 10.4816C17.5453 10.9592 16.968 11.1703 16.4305 11.1784C15.4886 11.1926 14.9433 10.9286 14.5077 10.7286L14.1684 12.2885C14.6059 12.4857 15.4136 12.657 16.2492 12.6661C18.2172 12.6661 19.504 11.7109 19.5094 10.2315ZM11.7554 5.43997L8.72183 12.5541H6.74263L5.24958 6.87621C5.15923 6.52768 5.08036 6.39891 4.80509 6.25175C4.35496 6.01122 3.61165 5.78632 2.9585 5.64647L3.00256 5.43997H6.18847C6.59393 5.43997 6.95934 5.70562 7.05251 6.16491L7.84109 10.2822L9.78849 5.43997H11.7554Z"
      fill="#2A2C6B"
    />
  </svg>
);

const VisaCard = (props) => {
  return <Icon component={Svg} {...props} />;
};

export default VisaCard;
