import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CloseOutlined } from '@ant-design/icons';

import styles from "./index.module.scss";

import { getObjectByCode } from "../../../../../utils/CurrencyEnum";
import Text from "../../../../../components/presentation/Text";
import VisaCardIcon from "../../../../../components/presentation/Icons/VisaCardIcon";
import MasterCardIcon from "../../../../../components/presentation/Icons/MasterCardIcon";
import MaestroCardIcon from "../../../../../components/presentation/Icons/MaestroCardIcon";
import PlusCardIcon from "../../../../../components/presentation/Icons/PlusCardIcon";

function SavedCardView(props) {
    const [showDelete, setShowDelete] = useState(false);
    const [deleteCard, setDeleteCard] = useState({});

    const intl = useIntl();

    const getCardBrandIcon = cardBrand => {
        if(cardBrand === "VISA")
            return <VisaCardIcon />;
        else if(cardBrand === "MASTERCARD")
            return <MasterCardIcon />;
        else if(cardBrand === "MAESTRO")
            return <MaestroCardIcon />;
        else 
            return "";
    }

    const onShowDelete = card => {
        setShowDelete(true);
        setDeleteCard(card);
    }

    const onDelete = id => {
        setShowDelete(false);
        props.deleteSavedCard(id);
    }

    const getCards = () => {
        return (
            props.userSavedCards.map(card => {
                return (
                    <div key={card.id} className={styles.card}>
                        <div 
                            className={styles["card-info"]}
                            onClick={() => props.savedCardRequest(card.id)}
                        >
                            {getCardBrandIcon(card.cardBrand)}
                            <Text onlyText={true} text={card.cardDetail} />
                        </div>
                        <div 
                            className={styles.delete}
                            onClick={() => onShowDelete(card)}
                        >
                            <div className={styles.divider} />
                            <CloseOutlined />
                        </div>
                    </div>
                )
            })
        );
    }

    if(showDelete) 
        return (
            <div className={styles["delete-content"]}>
                <span className={styles.title}>
                    <FormattedMessage id="global.presentation.savedCard.deleteCard">
                        {(...props) => props}
                    </FormattedMessage>
                </span>
                <div className={styles["card-detail"]}>
                    {getCardBrandIcon(deleteCard.cardBrand)}
                    <Text onlyText={true} text={deleteCard.cardDetail} />
                </div>
                <div className={styles.options}>
                    <span className={styles.yes} onClick={() => onDelete(deleteCard.id)}>
                        <CloseOutlined />{" "}
                        <FormattedMessage id="global.presentation.yes">
                            {(...props) => props}
                        </FormattedMessage>
                    </span>
                    <span className={styles.no} onClick={() => setShowDelete(false)}>
                        <FormattedMessage id="global.presentation.no">
                            {(...props) => props}
                        </FormattedMessage>
                    </span>
                </div>
            </div>
        );

    return (
        <div className={styles.content}>
            <span className={styles.title}>
                {`${intl.formatMessage({ id: "global.presentation.pay" })} ${props.data.amnt} ${getObjectByCode(props.data.curr).symbol}`}
            </span>
            <Text 
                className={styles["use-saved-card"]}
                translationId="global.presentation.savedCard.useSavedCard" 
            />
            <div className={styles.cards}>
                {getCards()}
            </div>
            <Text 
                className={styles.or}
                translationId="global.presentation.savedCard.or" 
            />
            <div 
                className={styles["use-new-card"]} 
                onClick={() => props.setShowSavedCard(false)}
            >
                <PlusCardIcon className={styles.plus} />
                <Text translationId="global.presentation.savedCard.useNewCard" />
            </div>
        </div>
    );
}

export default SavedCardView;