import React from "react";
import { Divider, Dropdown, Menu } from "antd";
import { connect } from "react-redux";
import { DownOutlined } from '@ant-design/icons';
import { useHistory } from "react-router-dom";
import QueryString from "query-string";

import styles from "./index.module.scss";

import Text from "../../../../components/presentation/Text";
import * as languageAction from "../../../../store/actions/languageAction";
import { LanguageEnum, getNameByCode } from "../../../../utils/LanguageEnum";
import LockIcon from "../../../../components/presentation/Icons/LockIcon";
import Popover from "../../../../components/presentation/Popover";

function Footer(props) {
    const history = useHistory();

    const changeLanguage = (code) => {
        let parsedWidgetData = QueryString.parse(history.location.search);

        parsedWidgetData.language = code;

        let widgetQuery = QueryString.stringify(parsedWidgetData);

        history.push(`${history.location.pathname}?${widgetQuery}`);

        props.changeLanguage(code);
    }

    const getLanguagesMenu = () => {
        return (
            <Menu>
                {
                    LanguageEnum.map(value => {
                        return (
                            <Menu.Item
                                onClick={() => changeLanguage(value.code)}
                                className={styles["language-item"]}
                                key={value.code}
                            >
                                <Text onlyText={true} text={value.name} />
                            </Menu.Item>
                        );
                    })
                }
            </Menu>
        );
    }

    return (
        <footer>
            <Popover
                textTranslationId="global.presentation.security.text"
            >
                <div className={styles.security}>
                    <LockIcon />
                    <Text translationId="global.presentation.security" />
                </div>
            </Popover>
            <Divider type="vertical" />
            <Dropdown
                overlay={getLanguagesMenu()}
                placement="topRight"
            >
                <div className={styles.language}>
                    <Text translationId="global.presentation.language" />
                    <Text onlyText={true} text={": " + getNameByCode(props.language)} />{" "}
                    <DownOutlined className={styles.arrow} />
                </div>
            </Dropdown>
        </footer>
    );
}

const mapStateToProps = (state) => {
    return {
        language: state.language.language
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeLanguage: (language) => dispatch(languageAction.changeLanguage(language))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);