import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";
import Axios from "axios";

import styles from "./index.module.scss";

import Loading from "../Widget/Loading";

function MediatorPayment(props) {
  const history = useHistory();

  useEffect(() => {
    let parsedWidgetData = getWidgetDataAndSanitize();

    mediatorPaymentIntentionRequest(parsedWidgetData);
  });

  const getWidgetDataAndSanitize = () => {
    let parsedWidgetData = QueryString.parse(history.location.search);

    return parsedWidgetData;
  };

  const mediatorPaymentIntentionRequest = (parsedWidgetData) => {
    Axios.post("/public/mediator-payment-intention", parsedWidgetData)
      .then((response) => {
        if (response.data.isValid === true) {
            window.top.location = response.data.redirectUrl;
        } else {
          history.push("/public/invalid-payment-intention");
        }
      })
      .catch((response) => {
        history.push("/public/invalid-payment-intention");
      });
  };

  return (
    <Loading subTitleTranslationId="global.presentation.loading.initPayment" />
  );
}

export default MediatorPayment;
