import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.5" cx="12" cy="12" r="11.5" stroke="currentColor" />
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 11V7H11V11H7V13H11V17H13V13H17V11H13Z"
      fill="currentColor"
    />
  </svg>
);

const PlusCardIcon = (props) => {
  return <Icon component={Svg} {...props} />;
};

export default PlusCardIcon;
