import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import QueryString from "query-string";
import Axios from "axios";

import Loading from "../Widget/Loading";
import PaymentFailed from "../Widget/PaymentFailed";
import PaymentSuccessful from "../Widget/PaymentSuccessful";

function PaymentResponse(props) {
    const [initialLoad, setInitialLoad] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [parsedData, setParsetData] = useState({});

    const history = useHistory();

    useEffect(() => {
        var parsedData = QueryString.parse(history.location.search);

        if(!initialLoad) 
        {
            setInitialLoad(true);
            paymentGatewayResponse(parsedData);
        }
    });

    const paymentGatewayResponse = parsedData => {
        setLoading(true);
        setParsetData(parsedData);

        Axios.post("/public/payment-gateway-response", parsedData)
            .then(response => {
                if(response.data.isValid === true) {
                    if(response.data.result === "OK")
                        setIsSuccess(true);
                        
                    setLoading(false);
                    
                    setTimeout(() => {
                        window.top.location = response.data.redirectUrl;
                    }, 2000);
                } else {
                    history.push("/public/payment-failed");
                }
            })
            .catch(response => {
                history.push("/public/payment-failed");
            })
    }

    if(loading)
        return <Loading subTitleTranslationId="global.presentation.loading.makePayment" />

    if(isSuccess)
        return <PaymentSuccessful hideReturn={true} />
    else
        return <PaymentFailed hideReturn={true} />
}

export default PaymentResponse;