import React, { useState } from "react";
import { Button } from "antd";
import { injectIntl } from "react-intl";

import styles from "./index.module.scss";

import CheckIcon from "../../../../components/presentation/Icons/CheckIcon";
import Text from "../../../../components/presentation/Text";
import { getObjectByCode } from "../../../../utils/CurrencyEnum";
import { getObjectsByCodes as getCzechObjectsByCodes } from "../../../../utils/CzechBanksEnum";
import { getObjectsByCodes as getSlovakiaObjectsByCodes } from "../../../../utils/SlovakiaBanksEnum";

function BankButtons(props) {
  const [selectedValue, setSelectedValue] = useState(false);

  const { intl } = props;

  const showRedirectText = () => {
    if (selectedValue !== false)
      return (
        <Text
          className={styles["redirect-text"]}
          translationId="global.presentation.bankButtons.redirectText"
        />
      );

    return "";
  };

  const setBankCode = (code) => {
    setSelectedValue(code);
    props.setBankType(code);
  };

  const getBankButtons = (currency) => {
    let bankButtons = [];

    if (currency === "203")
      bankButtons = getCzechObjectsByCodes(props.bankCodes);
    else bankButtons = getSlovakiaObjectsByCodes(props.bankCodes);

    return bankButtons.map((value) => {
      return (
        <div
          onClick={() => setBankCode(value.code)}
          className={[
            styles["bank-button"],
            value.code === selectedValue ? styles.active : "",
          ].join(" ")}
          key={value.code}
        >
          {value.logo}
          <CheckIcon className={styles.check} />
        </div>
      );
    });
  };

  return (
    <section className={styles.content}>
      <div className={styles["bank-buttons"]}>
        {getBankButtons(props.data.curr)}
      </div>
      {showRedirectText()}
      <Button
        onClick={() => props.bankPaymentRequest()}
        disabled={selectedValue === false}
        className={[
          styles.button,
          selectedValue !== false ? styles.active : "",
        ].join(" ")}
      >
        {`${intl.formatMessage({ id: "global.presentation.pay" })} ${
          props.data.amnt
        } ${getObjectByCode(props.data.curr).symbol}`}
      </Button>
    </section>
  );
}

export default injectIntl(BankButtons);
