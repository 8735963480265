import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.0214 10.1077L16.3938 6.25989L8.7545 10.1077H24.0214ZM20.3924 12.2534V23.8902H24.1515V12.2534H20.3924ZM14.5097 12.2534V23.8902H18.2688V12.2534H14.5097ZM8.63601 12.2534V23.8902H12.3848V12.2534H8.63601ZM5.38045 25.1758C5.01858 25.1758 4.73397 24.8904 4.73397 24.5297C4.73397 24.1782 5.01858 23.8902 5.38045 23.8902H6.49826V12.2534H4.26263C3.67153 12.2534 3.2002 11.7669 3.2002 11.1779C3.2002 10.7265 3.4745 10.3334 3.86857 10.179L15.9108 4.11935C16.2302 3.952 16.5792 3.97016 16.8664 4.11935L28.9962 10.2244C29.5216 10.4891 29.7316 11.1286 29.4663 11.6592C29.2834 12.0342 28.9099 12.2482 28.5133 12.2482V12.2534H26.2802V23.8902H27.4084C27.7586 23.8902 28.0432 24.1782 28.0432 24.5297C28.0432 24.8904 27.7586 25.1758 27.4084 25.1758H5.38045Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.26263 27.9468C3.67153 27.9468 3.2002 27.4668 3.2002 26.8818C3.2002 26.2863 3.67153 25.7998 4.26263 25.7998H28.5133C29.1057 25.7998 29.5886 26.2863 29.5886 26.8818C29.5886 27.4668 29.1057 27.9468 28.5133 27.9468H4.26263Z"
      fill="currentColor"
    />
  </svg>
);

const BankIcon = props => {
    return (
        <Icon component={Svg} {...props} />
    );
}

export default BankIcon;