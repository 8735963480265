import React from "react";
import { Popover as APopover } from "antd";

import styles from "./Popover.module.scss";

import Text from "./Text";

function Popover(props) {
  return (
    <APopover
      trigger="hover"
      placement={props.placement === undefined ? "top" : props.placement}
      overlayClassName={styles["popover-overlay"]}
      content={
          <div className={styles.content}>
            <Text
                className={styles.text}
                translationId={props.textTranslationId}
            />
            <Text 
                className={styles["sub-text"]}
                onlyText={true} 
                text={props.subText}
            />
          </div>
      }
    >
      {props.children}
    </APopover>
  );
}

export default Popover;