import React, { useState } from "react";
import { Button, Switch } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useIntl } from "react-intl";

import styles from "./index.module.scss";

import Text from "../../../../../components/presentation/Text";
import VisaCard from "../../../../../components/presentation/Icons/VisaCardIcon";
import MasterCardIcon from "../../../../../components/presentation/Icons/MasterCardIcon";
import MaestroCardIcon from "../../../../../components/presentation/Icons/MaestroCardIcon";
import Popover from "../../../../../components/presentation/Popover";
import { getObjectByCode } from "../../../../../utils/CurrencyEnum";
import CardBackIcon from "../../../../../components/presentation/Icons/CardBackIcon";

function CreditCardView(props) {
  const [isCreditCardValid, setIsCreditCardValid] = useState(false);
  const [isCvvValid, setCvvValid] = useState(false);
  const [isMonthValid, setMonthValid] = useState(false);
  const [isYearValid, setYearValid] = useState(false);
  const [cardData, setCardData] = useState({
    creditCard: "",
    month: null,
    year: null,
    cvv: "",
    saveCard: false,
  });

  const monthInput = React.createRef();
  const yearInput = React.createRef();
  const cvvInput = React.createRef();

  const intl = useIntl();

  const onCreditCardChange = (value) => {
    let cardNumberLength = 16;

    value = value.replace(/\s/g, "");

    if (isNaN(value) || value.length > cardNumberLength) return;

    var isValid = value.length === cardNumberLength;
    setIsCreditCardValid(isValid);

    let matchedFourCharacters = value.match(/.{1,4}/g);

    if (value === null || value === "")
      setCardData({ ...cardData, creditCard: "" });
    else
      setCardData({ ...cardData, creditCard: matchedFourCharacters.join(" ") });

    if (isValid) {
      monthInput.current.focus();
    }
  };

  const onCvvChange = (value) => {
    let cvvLength = 3;

    if (isNaN(value) || value.length > cvvLength) return;

    setCvvValid(value.length === cvvLength);

    setCardData({ ...cardData, cvv: value });
  };

  const onMonthChange = (value) => {
    if (isNaN(value) || value.length > 2) return;

    let isValid = value.length == 2 && value > 0 && value < 13;
    setMonthValid(isValid);
    setCardData({ ...cardData, month: value });

    if (isValid) {
      yearInput.current.focus();
    }
  };

  const onYearChange = (value) => {
    if (isNaN(value) || value.length > 2) return;

    let isValid = value.length == 2 && value >= 0 && value <= 99;
    setYearValid(isValid);
    setCardData({ ...cardData, year: value });

    if (isValid) {
      cvvInput.current.focus();
    }
  };

  const onExpFocus = (element) => {
    let parent = element.parentElement;
    if (parent != undefined) {
      parent.classList.add(styles.focus);
    }
  };

  const onExpBlur = (element) => {
    let parent = element.parentElement;
    if (parent != undefined) {
      parent.classList.remove(styles.focus);
    }
  };

  const isCardValid = () => {
    return isCreditCardValid && isMonthValid && isYearValid && isCvvValid;
  };

  if (props.forceCardRedirect)
    return (
      <section className={styles.content}>
        <Text translationId="global.presentation.tempRedirect" />
        <Button
          onClick={() =>
            props.creditCardRequest({
              creditCard: "0000 0000 0000 0000",
              month: "01",
              year: "00",
              cvv: "000",
              saveCard: false,
            })
          }
          className={[styles.button, styles.active].join(" ")}
        >
          {intl.formatMessage({ id: "global.presentation.redirect" })}
        </Button>
      </section>
    );

  return (
    <section className={styles.content}>
      <div className={styles["card-info"]}>
        <div className={styles.header}>
          <div className={styles.left}>
            <Text translationId="global.presentation.cardNumber" />
          </div>
          <div className={styles.right}>
            <VisaCard />
            <MasterCardIcon />
            <MaestroCardIcon />
            <Popover
              textTranslationId="global.presentation.creditCard.cardNumber.info"
              subText="0000 0000 0000 0000"
            >
              <InfoCircleOutlined className={styles.info} />
            </Popover>
          </div>
        </div>
        <input
          onChange={(e) => onCreditCardChange(e.target.value)}
          value={cardData.creditCard}
          id="ccnum"
          name="cardnumber"
          type="tel"
          autoComplete="cc-number"
        />
        <div
          className={[
            styles.validator,
            cardData.creditCard.length > 0 ? styles["has-value"] : "",
            isCreditCardValid ? styles["is-valid"] : "",
          ].join(" ")}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.expiration}>
          <div className={styles.header}>
            <div className={styles.left}>
              <Text translationId="global.presentation.creditCard.expirationTo" />
            </div>
            <div className={styles.right}>
              <Popover
                textTranslationId="global.presentation.creditCard.expirationTo.info"
                subText="02/19"
              >
                <InfoCircleOutlined className={styles.info} />
              </Popover>
            </div>
          </div>
          <div className={styles.options}>
            <input
              placeholder={intl.formatMessage({
                id: "global.presentation.month",
              })}
              className={styles.month}
              onChange={(e) => onMonthChange(e.target.value)}
              value={cardData.month ?? ""}
              id="expmonth"
              name="expmonth"
              autoComplete="cc-exp-month"
              onBlur={(e) => onExpBlur(e.target)}
              onFocus={(e) => onExpFocus(e.target)}
              type="tel"
              maxLength="2"
              ref={monthInput}
            />
            <Text onlyText={true} text={" / "} />
            <input
              placeholder={intl.formatMessage({
                id: "global.presentation.year",
              })}
              className={styles.year}
              onChange={(e) => onYearChange(e.target.value)}
              value={cardData.year ?? ""}
              id="expyear"
              name="expyear"
              autoComplete="cc-exp-year"
              onBlur={(e) => onExpBlur(e.target)}
              onFocus={(e) => onExpFocus(e.target)}
              type="tel"
              maxLength="2"
              ref={yearInput}
            />
            <div
              className={[
                styles.validator,
                cardData.month !== null || cardData.year != null
                  ? styles["has-value"]
                  : "",
                isMonthValid && isYearValid ? styles["is-valid"] : "",
              ].join(" ")}
            />
          </div>
        </div>
        <div className={styles.cvv}>
          <div className={styles.header}>
            <div className={styles.left}>
              <Text translationId="global.presentation.creditCard.cvv" />
            </div>
            <div className={styles.right}>
              <Popover
                textTranslationId="global.presentation.creditCard.cvv.info"
                subText="123"
              >
                <InfoCircleOutlined className={styles.info} />
              </Popover>
            </div>
          </div>
          <div className={styles.input}>
            <input
              onChange={(e) => onCvvChange(e.target.value)}
              value={cardData.cvv}
              id="cvv"
              name="cvv"
              type="tel"
              autoComplete="cc-csc"
              ref={cvvInput}
            />
            <CardBackIcon className={styles["card-back"]} />
          </div>
          <div
            className={[
              styles.validator,
              cardData.cvv.length > 0 ? styles["has-value"] : "",
              isCvvValid ? styles["is-valid"] : "",
            ].join(" ")}
          />
        </div>
      </div>
      <div
        className={styles["remember-card"]}
        onClick={() =>
          setCardData({ ...cardData, saveCard: !cardData.saveCard })
        }
      >
        <Switch
          checkedChildren={intl.formatMessage({
            id: "global.presentation.yes",
          })}
          unCheckedChildren={intl.formatMessage({
            id: "global.presentation.no",
          })}
          className={styles.switch}
          checked={cardData.saveCard}
        />
        <Text translationId="global.presentation.creditCard.remember" />
        <Popover textTranslationId="global.presentation.creditCard.remember.info">
          <InfoCircleOutlined className={styles.info} />
        </Popover>
      </div>
      <Button
        onClick={() => props.creditCardRequest(cardData)}
        disabled={!isCardValid()}
        className={[styles.button, isCardValid() ? styles.active : ""].join(
          " "
        )}
      >
        {`${intl.formatMessage({ id: "global.presentation.pay" })} ${
          props.data.amnt
        } ${getObjectByCode(props.data.curr).symbol}`}
      </Button>
    </section>
  );
}

export default CreditCardView;