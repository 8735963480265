export const LanguageEnum = Object.freeze([
    { code: "sk", name: "Slovenčina"},
    { code: "cz", name: "Čeština"},
    { code: "hu", name: "Magyar"},
    { code: "pl", name: "Polskie"},
    { code: "ro", name: "Română"},
    { code: "en", name: "English"},
    { code: "de", name: "Deutsche"}
]);

export const getNameByCode = code => {
    return LanguageEnum.find(language => {
        return language.code === code;
    })?.name;
}

export const getCodeOrDefaultByCode = code => {
    code = code?.toLowerCase();

    let value = LanguageEnum.find(language => {
        return language.code === code;
    })?.code
    
    if(value === null || value === undefined)
        return "sk";

    return value;
}