import React from "react";

import Respond from "../../../../components/presentation/Respond";

function InvalidPaymentIntention(props) {
    return (
        <Respond 
            type="error"
            titleTranslationId="global.presentation.invalidPaymentIntention.title"
            hideReturn={true}
        />
    );
}

export default InvalidPaymentIntention;