import React from "react";

import Respond from "../../../../components/presentation/Respond";

function PaymentSuccessful(props) {
    return (
        <Respond 
            type="success"
            titleTranslationId="global.presentation.paymentSuccessful.title"
            subTitleTranslationId="global.presentation.thankYou"
            hideReturn={props.hideReturn}
        />
    );
}

export default PaymentSuccessful;