import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { withRouter } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";

import Widget from "../pages/presentation/Widget/index";
import InvalidPaymentIntention from "../pages/presentation/Widget/InvalidPaymentIntention";
import PaymentFailed from "../pages/presentation/Widget/PaymentFailed";
import NotFound from "../pages/presentation/NotFound";
import VirtualPayment from "../pages/presentation/VirtualPayment";
import PaymentResponse from "../pages/presentation/PaymentResponse";
import Home from "../pages/presentation/Home";
import MediatorPayment from "../pages/presentation/MediatorPayment";
import ViamoNotification from "../pages/presentation/VIAMO";

class Routers extends Component {
    static displayName = ProtectedRoute.name;

    adminRouter = () => {
      return (
        <Switch>
          <Route
            exact
            path="/"
            render={() => <Home />}
          />
          {/* This path is from previous account web. Redirect to home to show redirect path to new web */}
          <Route
            exact
            path="/prihlasenie"
            render={() => <Home />}
          />
          <Route 
            exact 
            path="/public/payments-inline-widget" 
            render={() => <Widget />} 
          />
          {/* This path is temporary solution */}
          <Route
            exact
            path="/public/payments-gateways-page-generator"
            render={() => <MediatorPayment /> }
          />
          <Route
            exact
            path="/public/invalid-payment-intention"
            render={() => <InvalidPaymentIntention />}
          />
          <Route
            exact
            path="/public/payment-failed"
            render={() => <PaymentFailed />}
          />
          <Route
            exact
            path="/public/virtual-payment"
            render={() => <VirtualPayment />}
          />
          <Route
            exact
            path="/public/payment-response"
            render={() => <PaymentResponse />}
          />
          <Route
            exact
            path="/viamo/notification"
            render={() => <ViamoNotification />}
          />
          <Route
            render={() => <NotFound />}
          />          
        </Switch>
      );
    }
  
    render = () => {
      return this.adminRouter();
    }
  }
  
  const mapStateToProps = state => {
    return {};
  };
  
  const mapDispatchToProps = dispatch => {
    return {};
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(Routers));