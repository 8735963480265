import React, { useState, useEffect } from "react";

import styles from "./index.module.scss";

import CreditCardView from "./CreditCardView";
import SavedCardView from "./SavedCardView";

function CreditCard(props) {
    const [showSavedCard, setShowSavedCard] = useState(false);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [userSavedCardsLength, setUserSavedCardsLength] = useState(undefined);

    useEffect(() => {
        if(isInitialLoad || userSavedCardsLength !== props.userSavedCards.length) {
            setIsInitialLoad(false);
            setUserSavedCardsLength(props.userSavedCards.length);

            if(props.userSavedCards !== undefined && props.userSavedCards.length > 0)
                setShowSavedCard(true);
            else 
                setShowSavedCard(false);
        }
    })

    return (
        <section className={styles.content}>
            {
                showSavedCard ?
                <SavedCardView 
                    data={props.data}
                    userSavedCards={props.userSavedCards}
                    setShowSavedCard={setShowSavedCard}
                    savedCardRequest={props.savedCardRequest}
                    deleteSavedCard={props.deleteSavedCard}
                />
                :
                <CreditCardView
                    data={props.data}
                    creditCardRequest={props.creditCardRequest}
                    forceCardRedirect={props.forceCardRedirect}
                />
            }
        </section>
    );
}

export default CreditCard;
