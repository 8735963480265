import React from "react";
import Icon from "@ant-design/icons";

const Svg = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity="0.5"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.43934 6.09619L6.09619 0.43934C6.68198 -0.146447 7.63173 -0.146447 8.21751 0.43934C8.8033 1.02513 8.8033 1.97487 8.21751 2.56066L5.12132 5.65685H13.1569C13.9853 5.65685 14.6569 6.32843 14.6569 7.15685C14.6569 7.98528 13.9853 8.65685 13.1569 8.65685H5.12132L8.21751 11.753C8.8033 12.3388 8.8033 13.2886 8.21751 13.8744C7.63173 14.4602 6.68198 14.4602 6.09619 13.8744L0.43934 8.21751C-0.146447 7.63173 -0.146447 6.68198 0.43934 6.09619Z"
      fill="#091C30"
    />
  </svg>
);

const BackArrowIcon = (props) => {
  return <Icon component={Svg} {...props} />;
};

export default BackArrowIcon;
